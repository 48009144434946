.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  padding: 1rem;
  z-index: 1000;
}

.self-portrait-sidebar-size {
  height: 150px;
  width: 150px;
}

.fixed {
  position: sticky;
  top: 0;
}

.sidebar-link {
  transition: 0.5s;
}

.active-link {
  font-weight: bold;
}

.sidebar-link::before {
  display: inline-block;
  content: "";
  border-top: 1px solid gray;
  width: 1rem;
  margin: 0 1rem;
  transform: translateY(-0.25rem);
  transition: 0.2s;
}

.active-link::before {
  border-top: 2px solid black;
  width: 3rem;
}
