.self-portrait {
  border: 2px solid black;
  background-size: cover;
  background-position: center;
  animation: mutate 8s infinite;
  background-image: url("../../assets/me.jpg");
}

.self-portrait-home-size {
  height: 275px;
  width: 275px;
}

.flag {
  margin-left: 4px;
  width: 16px;
  display: inline;
}

@keyframes mutate {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }
  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}
